import React, { useState } from 'react';
import './Upload.css';
import XLSX from 'xlsx';
import './Uploadtwo.css';
import CSVReader from "react-csv-reader";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function Uploadtwo(){ 
    const [items, setItems] = useState([]);
    var bilder = [];
    var autoren =[];
    var givenImages = [];
    var iteration = 0;

    function rand (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function sortRatingFive(a, b){
      if(a.ratingFiveStar.length < b.ratingFiveStar.length) {
          return -1;
      } else {
          return 1;
      }
  }

  function sortRatingFour(a, b){
    if(a.ratingFourStar.length < b.ratingFourStar.length) {
        return -1;
    } else {
        return 1;
    }
}

function sortRatingThree(a, b){
  if(a.ratingThreeStar.length < b.ratingThreeStar.length) {
      return -1;
  } else {
      return 1;
  }
}

function sortRatingTwo(a, b){
  if(a.ratingTwoStar.length < b.ratingTwoStar.length) {
      return -1;
  } else {
      return 1;
  }
}

function sortRatingOne(a, b){
  if(a.ratingOneStar.length < b.ratingOneStar.length) {
      return -1;
  } else {
      return 1;
  }
}

function showUnusedImages() {
  var fiveStar = [];
  items.forEach( bild => {
    if(bild.givenTo === ""){
      fiveStar.push(bild);
    }
  });
  console.log("Unbenutzte Bilder");
  console.log(fiveStar);
}


// Tippfehler korrigieren
function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = [];
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}



    // Autoren Tabelle Initial füllen
    function  initialAutorFill() {
      items.forEach(bild => {

  
        var index = autoren.findIndex(x => x.name === bild.autor);
  
        if (index === -1) { // Autor  noch nicht in der Liste
            autoren.push(new Autor(bild.autor, 1, 0, bild.name));
        } else {
          //  console.log("object already exists");
            autoren.forEach(x => {

                if (x.name === bild.autor) {
                    x.numberOfImages += 1;
                    x.images += (bild.name);
                }
                // ToDo: Remove Line 
                if(x.name === "DN_P" || x.name ==="Shippochan1000"){
                  x.score = 40;
                }

                // Bevorzugung für Ismael
                if(x.name === "Ismael"){
                 // x.score = -5;
                }
            });

        }
    });

      
    }

    function initializeParams() {

     // window.location.reload();
    // const [items, setItems] = useState([]);
     //var bilder = [];
    // var autoren =[];
     //var givenImages = [];
    // var iteration = 0;

    bilder.forEach(bild => {
     bild.givenTo = "";
    })

    autoren.forEach(autor => {
      autor.getImageCount = 0;
      autor.score = 0;
      autor.giftImage = [];
      autor.quersumme = 0;
    });

    iteration = 0;

     console.log(items);
    }

    function calculateDistributionComplete(step = 5) {
   if(iteration === 0){
       initialAutorFill();
    
   }
      switch (step) {
        case 0:

          calculateResult();
          break;
        case 1:
          calculateDistributionStepFive();
          break;
        case 2:
          calculateDistributionStepFour();
          break; 
        case 3:
            calculateDistributionStepThree();
            break; 
        case 4:
          calculateDistributionStepTwo();
            break;
        
        case 5: // Zweite und folgende Interation
               
               iteration ++;
               calculateDistributionStepOne();
              
     
               break;                  
        default:

     if (iteration > 0){
      initializeParams();
     }        
          console.log(bilder)
          iteration ++;
          calculateDistributionStepOne();
         

          break;
      }
            
    }

    function calculateResult() 
    {

      if(iteration < 10){
        calculateDistributionComplete(5);
        console.warn(" Iteration", iteration);
      }

      console.error("finish");
      showUnusedImages();
      autoren.forEach(autor =>{
        console.log(autor.name, "Queersumme", autor.quersumme, "Fehlende Bilder", autor.numberOfImages - autor.getImageCount, "Score ", autor.score);
      })

      console.log(givenImages);
      console.log(autoren);

      var exportBtn = document.getElementById("exportBtn");
      exportBtn.classList.remove("invisible");

    }

    function calculateDistributionStepFive() {





      
    items.sort(sortRatingOne);
     
    // Für jedes einzelne Bild (bild)
    items.forEach(bild =>  {
      var getAutorenWitheOneStars =[];


      // Vergebene Bilder direkt überspringen
      if(bild.givenTo !== ""){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }

      if(bild.ratingOneStar === 0){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }
  

      if(bild.ratingOneStar.length > 0){
          
          var tempOneStarsBewertungen = bild.ratingOneStar;

          // Für jeden der das bild mit 5 Sternen bewertet hat
          tempOneStarsBewertungen.forEach(ratingPerson => {

        

            // für jeden Autor
       
            autoren.forEach(autor => {
      

             
              // Wenn der Autor in der 5 Sterne Liste gefunden wurde und nicht bereits seine Anzhal an Bildeer 
             
            
              
             
                if(similarity(autor.name,ratingPerson)> 0.5) {
                //console.log(y);
               
               
                // Autor in die 5 Sterne Bewertung reintuhen

                var minScore = Math.min.apply(null, autoren.map(function(scoreAutoren) {
                  return scoreAutoren.score;
                }));
               
                // Ausnahme damit die 1 Sterne auch vergeben werden
                if (autor.score >= minScore && autor.getImageCount !== autor.numberOfImages) {
                  
                getAutorenWitheOneStars.push(autor);
         

                }

              
            }
            })
        


       
          })
      } // 5 Sterne wurden alle in die Liste gepusht wir haben immmer noch das aktuelle Bild in der Hand


      // Ab hier sortieren nach Score und Bild vergeben
      var numberOfOneStarRatings = getAutorenWitheOneStars.length;

   

      if(numberOfOneStarRatings < 1){
        return;
      }
      //console.log(numberOfFiveStarRatings);
      
      // Zufällig einen aus dem Array auswählen

    
      var theChoosenOneNumber = rand(0,numberOfOneStarRatings-1);

      var theChoosenOne = getAutorenWitheOneStars[theChoosenOneNumber];

      // Set The ChoosenOne
      var doubleAuthor = true;
      givenImages.forEach(givenImage => {
        if(givenImage.gifted === theChoosenOne.name && givenImage.author === bild.autor ){
          doubleAuthor = false;
        }


      });
      if(doubleAuthor){
        bild.givenTo = theChoosenOne.name;
        givenImages.push(new GiftImageRatingAuthor(theChoosenOne.name,bild.name,1,bild.autor));
        theChoosenOne.score +=1;
        theChoosenOne.getImageCount ++;
        theChoosenOne.quersumme = theChoosenOne.score / theChoosenOne.getImageCount;

        // Benutzer ist durch
        if(theChoosenOne.getImageCount === theChoosenOne.numberOfImages){
          theChoosenOne.score = 99;

        }

      }

    });

    console.log(givenImages);
    console.log(autoren);

    calculateDistributionComplete(0);




  
    }


    function calculateDistributionStepFour() {



      

// Step 1 = 5 Star Bewertungen verteilen


     
    // console.log(autoren);
    // console.log(items);

    items.sort(sortRatingTwo);
     
    // Für jedes einzelne Bild (bild)
    items.forEach(bild =>  {
      var getAutorenWitheTwoStars =[];


      // Vergebene Bilder direkt überspringen
      if(bild.givenTo !== ""){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }

      if(bild.ratingTwoStar === 0){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }
  

      if(bild.ratingTwoStar.length > 0){
          
          var tempTwoStarsBewertungen = bild.ratingTwoStar;

          // Für jeden der das bild mit 5 Sternen bewertet hat
          tempTwoStarsBewertungen.forEach(ratingPerson => {

        

            // für jeden Autor
       
            autoren.forEach(autor => {
      

             
              // Wenn der Autor in der 5 Sterne Liste gefunden wurde und nicht bereits seine Anzhal an Bildeer 
             
            

             
                if(similarity(autor.name,ratingPerson)> 0.5) {
                //console.log(y);
               
               
                // Autor in die 5 Sterne Bewertung reintuhen

                var minScore = Math.min.apply(null, autoren.map(function(scoreAutoren) {
                  return scoreAutoren.score;
                }));
                
                if (autor.score === minScore && autor.getImageCount !== autor.numberOfImages) {
                  
                getAutorenWitheTwoStars.push(autor);
                //console.log(autor);

                }

              
            }
            })
        


       
          })
      } // 5 Sterne wurden alle in die Liste gepusht wir haben immmer noch das aktuelle Bild in der Hand


      // Ab hier sortieren nach Score und Bild vergeben
      var numberOfTwoStarRatings = getAutorenWitheTwoStars.length;

   

      if(numberOfTwoStarRatings < 1){
        return;
      }
      //console.log(numberOfFiveStarRatings);
      
      // Zufällig einen aus dem Array auswählen

    
      var theChoosenOneNumber = rand(0,numberOfTwoStarRatings-1);

      var theChoosenOne = getAutorenWitheTwoStars[theChoosenOneNumber];

      // Set The ChoosenOne
      var doubleAuthor = true;
      givenImages.forEach(givenImage => {
        if(givenImage.gifted === theChoosenOne.name && givenImage.author === bild.autor ){
          doubleAuthor = false;
        }


      });
      if(doubleAuthor){
        bild.givenTo = theChoosenOne.name;
        givenImages.push(new GiftImageRatingAuthor(theChoosenOne.name,bild.name,2,bild.autor));
        theChoosenOne.score +=2;
        theChoosenOne.getImageCount ++;
        theChoosenOne.quersumme = theChoosenOne.score / theChoosenOne.getImageCount;

        
        // Benutzer ist durch
        if(theChoosenOne.getImageCount === theChoosenOne.numberOfImages){
          theChoosenOne.score = 99;

        }

      }

    });

    console.log(givenImages);
    console.log(autoren);

    calculateDistributionComplete(1);


     // Ab hier die 4 Sterne logic

   
    }


    function calculateDistributionStepThree() {



// Step 1 = 5 Star Bewertungen verteilen


     
    // console.log(autoren);
    // console.log(items);

    items.sort(sortRatingThree);
     
    // Für jedes einzelne Bild (bild)
    items.forEach(bild =>  {
      var getAutorenWitheThreeStars =[];


      // Vergebene Bilder direkt überspringen
      if(bild.givenTo !== ""){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }

      if(bild.ratingThreeStar === 0){
        return; // Mit dem nächsten Bild weitermachen und komplett zurück
      }
  

      if(bild.ratingThreeStar.length > 0){
          
          var tempThreeStarsBewertungen = bild.ratingThreeStar;

          // Für jeden der das bild mit 5 Sternen bewertet hat
          tempThreeStarsBewertungen.forEach(ratingPerson => {

        

            // für jeden Autor
       
            autoren.forEach(autor => {
      

             
              // Wenn der Autor in der 5 Sterne Liste gefunden wurde und nicht bereits seine Anzhal an Bildeer 
             
            

             
                if(similarity(autor.name,ratingPerson)> 0.5) {
                //console.log(y);
               
               
                // Autor in die 5 Sterne Bewertung reintuhen

                var minScore = Math.min.apply(null, autoren.map(function(scoreAutoren) {
                  return scoreAutoren.score;
                }));
                
                if (autor.score === minScore && autor.getImageCount !== autor.numberOfImages) {
                  
                getAutorenWitheThreeStars.push(autor);
                //console.log(autor);

                }

              
            }
            })
        


       
          })
      } // 5 Sterne wurden alle in die Liste gepusht wir haben immmer noch das aktuelle Bild in der Hand


      // Ab hier sortieren nach Score und Bild vergeben
      var numberOfThreeStarRatings = getAutorenWitheThreeStars.length;

   

      if(numberOfThreeStarRatings < 1){
        return;
      }
      //console.log(numberOfFiveStarRatings);
      
      // Zufällig einen aus dem Array auswählen

    
      var theChoosenOneNumber = rand(0,numberOfThreeStarRatings-1);

      var theChoosenOne = getAutorenWitheThreeStars[theChoosenOneNumber];

      // Set The ChoosenOne
      var doubleAuthor = true;
      givenImages.forEach(givenImage => {
        if(givenImage.gifted === theChoosenOne.name && givenImage.author === bild.autor ){
          doubleAuthor = false;
        }


      });
      if(doubleAuthor){
        bild.givenTo = theChoosenOne.name;
        givenImages.push(new GiftImageRatingAuthor(theChoosenOne.name,bild.name,3,bild.autor));
        theChoosenOne.score +=3;
        theChoosenOne.getImageCount ++;
        theChoosenOne.quersumme = theChoosenOne.score / theChoosenOne.getImageCount;

        
        // Benutzer ist durch
        if(theChoosenOne.getImageCount === theChoosenOne.numberOfImages){
          theChoosenOne.score = 99;

        }

      }

    });



    calculateDistributionComplete(2);


     // Ab hier die 4 Sterne logic

   
         
       }



    

    function calculateDistributionStepTwo() {
    // Step 1 = 5 Star Bewertungen verteilen


     
    // console.log(autoren);
    // console.log(items);

     items.sort(sortRatingFour);
     
     // Für jedes einzelne Bild (bild)
     items.forEach(bild =>  {
       var getAutorenWitheFourStars =[];


       // Vergebene Bilder direkt überspringen
       if(bild.givenTo !== ""){
         return; // Mit dem nächsten Bild weitermachen und komplett zurück
       }

       if(bild.ratingFourStar === 0){
         return; // Mit dem nächsten Bild weitermachen und komplett zurück
       }
   

       if(bild.ratingFourStar.length > 0){
           
           var tempfourStarsBewertungen = bild.ratingFourStar;

           // Für jeden der das bild mit 5 Sternen bewertet hat
           tempfourStarsBewertungen.forEach(ratingPerson => {

         

             // für jeden Autor
        
             autoren.forEach(autor => {
       
 
              
               // Wenn der Autor in der 5 Sterne Liste gefunden wurde und nicht bereits seine Anzhal an Bildeer 
              
             

              
                 if(similarity(autor.name,ratingPerson)> 0.5) {
                 //console.log(y);
                
                
                 // Autor in die 5 Sterne Bewertung reintuhen

                 var minScore = Math.min.apply(null, autoren.map(function(scoreAutoren) {
                   return scoreAutoren.score;
                 }));
                 
                 if (autor.score === minScore && autor.getImageCount !== autor.numberOfImages) {
                   
                 getAutorenWitheFourStars.push(autor);
                 //console.log(autor);

                 }

               
             }
             })
         


        
           })
       } // 5 Sterne wurden alle in die Liste gepusht wir haben immmer noch das aktuelle Bild in der Hand


       // Ab hier sortieren nach Score und Bild vergeben
       var numberOfFourStarRatings = getAutorenWitheFourStars.length;

    

       if(numberOfFourStarRatings < 1){
         return;
       }
       //console.log(numberOfFiveStarRatings);
       
       // Zufällig einen aus dem Array auswählen

     
       var theChoosenOneNumber = rand(0,numberOfFourStarRatings-1);

       var theChoosenOne = getAutorenWitheFourStars[theChoosenOneNumber];

       // Set The ChoosenOne
       var doubleAuthor = true;
       givenImages.forEach(givenImage => {
         if(givenImage.gifted === theChoosenOne.name && givenImage.author === bild.autor ){
           doubleAuthor = false;
         }


       });
       if(doubleAuthor){
         bild.givenTo = theChoosenOne.name;
         givenImages.push(new GiftImageRatingAuthor(theChoosenOne.name,bild.name,4,bild.autor));
         theChoosenOne.score +=4;
         theChoosenOne.getImageCount ++;
         theChoosenOne.quersumme = theChoosenOne.score / theChoosenOne.getImageCount;

         
        // Benutzer ist durch
        if(theChoosenOne.getImageCount === theChoosenOne.numberOfImages){
          theChoosenOne.score = 99;

        }

       }

     });


     calculateDistributionComplete(3);


      // Ab hier die 4 Sterne logic

    
          
        }
    


    function calculateDistributionStepOne() {
    // Step 1 = 5 Star Bewertungen verteilen
      
    
     
    

     
     // console.log(autoren);
     // console.log(items);

      items.sort(sortRatingFive);
      
      // Für jedes einzelne Bild (bild)
      items.forEach(bild =>  {
        var getAutorenWitheFiveStars =[];


        // Vergebene Bilder direkt überspringen
        if(bild.givenTo !== ""){
          return; // Mit dem nächsten Bild weitermachen und komplett zurück
        }

        if(bild.ratingFiveStar === 0){
          return; // Mit dem nächsten Bild weitermachen und komplett zurück
        }
    

        if(bild.ratingFiveStar.length > 0){
            
            var tempfiveStarsBewertungen = bild.ratingFiveStar;
            
            // Für jeden der das bild mit 5 Sternen bewertet hat
            tempfiveStarsBewertungen.forEach(ratingPerson => {

              // für jeden Autor
              autoren.forEach(autor => {
                
  
               
                // Wenn der Autor in der 5 Sterne Liste gefunden wurde und nicht bereits seine Anzhal an Bildeer 
              
                if(similarity(autor.name,ratingPerson)> 0.5) {
                  //console.log(y);

                  // Autor in die 5 Sterne Bewertung reintuhen

                  var minScore = Math.min.apply(null, autoren.map(function(scoreAutoren) {
                    return scoreAutoren.score;
                  }));
                  
                  if (autor.score === minScore && autor.getImageCount !== autor.numberOfImages) {
                    
                  getAutorenWitheFiveStars.push(autor);
                  //console.log(autor);

                  }

                
              }
              })
          


         
            })
        } // 5 Sterne wurden alle in die Liste gepusht wir haben immmer noch das aktuelle Bild in der Hand


        // Ab hier sortieren nach Score und Bild vergeben
        var numberOfFiveStarRatings = getAutorenWitheFiveStars.length;

     

        if(numberOfFiveStarRatings < 1){
          return;
        }
        //console.log(numberOfFiveStarRatings);
        
        // Zufällig einen aus dem Array auswählen

      
        var theChoosenOneNumber = rand(0,numberOfFiveStarRatings-1);

        var theChoosenOne = getAutorenWitheFiveStars[theChoosenOneNumber];

        // Set The ChoosenOne
        var doubleAuthor = true;
        givenImages.forEach(givenImage => {
          if(givenImage.gifted === theChoosenOne.name && givenImage.author === bild.autor ){
            doubleAuthor = false;
          }


        });
        if(doubleAuthor){
          bild.givenTo = theChoosenOne.name;
          givenImages.push(new GiftImageRatingAuthor(theChoosenOne.name,bild.name,5,bild.autor));
          theChoosenOne.score +=5;
          theChoosenOne.getImageCount ++;
          theChoosenOne.quersumme = theChoosenOne.score / theChoosenOne.getImageCount;

          
        // Benutzer ist durch
        if(theChoosenOne.getImageCount === theChoosenOne.numberOfImages){
          theChoosenOne.score = 99;

        }

        }

      });


      calculateDistributionComplete(4);


       // Ab hier die 4 Sterne logic


      
    }

  




     
    
  

   

    const readExcel = (file) => {
      
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
    
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
    
            const wb = XLSX.read(bufferArray, { type: "buffer" });
    
            const wsname = wb.SheetNames[0];
    
            const ws = wb.Sheets[wsname];
    
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };

          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        promise.then((d) => {
       
          d.forEach(element => {
           
            //console.log("element");
           // console.log(element.__EMPTY, " ", element.__EMPTY_1);
         
            bilder.push(new Bild(element.__EMPTY, element.__EMPTY_1));
          });

         
          

          var tableElement = document.getElementById("uploadRatings");
          tableElement.classList.remove("invisible");
          
          //console.log("Bilder");
          //console.log(bilder);
         });
      };

         
const handleForce = (data, fileInfo) => {
  
  //console.log(data);

  data.forEach(bewertung => {

    //if (bewertung.autor === "Ismael") {
      //console.log(bewertung);
      //console.log(bewertung['Teilnehmer:']);
      var participant = bewertung['Teilnehmer:'];


    var elements = Object.keys(bewertung).length;


    for (let index = 0; index < elements; index++) {
     

      var secondKey = Object.keys(bewertung)[index];
      
      if ((Object.keys(bewertung)[index]).match(/\[(.*?)\]/) !=null){
       
      var imageName = (Object.keys(bewertung)[index]).match(/\[(.*?)\]/)[0].replace(/[\[\]']+/g,''); // eslint-disable-line no-useless-escape

      var ratingOld = bewertung[secondKey];

   

      bilder.forEach(element => { // eslint-disable-line no-loop-func
       // if (element.name.includes(imageName)){
           
         if(similarity(element.name,imageName) > 0.9 || element.name.includes(imageName)) {
         // console.log("bilder imagename:", element.name, " bewertungsname:", imageName );
          //element[ratingNew].push(bewertung['Teilnehmer:']);



          switch (ratingOld) {
            case 5:
            case "Favo des Teilnehmers":
             element.ratingFiveStar.push(participant);
             // console.log(`${element.name} von ${element.autor} hat eine 5 Sterne von ${participant} `);
             
             // autoren.push(new Autor(element.autor,1,element.name));
         
            //  console.log(autoren[element.autor]);

              return "ratingFiveStar"
              case 4:
                //element.ratingFourStar +=(`${participant}`);
                element.ratingFourStar.push(participant);
                return "ratingFourStar"
                case 3:
                  //element.ratingThreeStar +=(`${participant} \n `);
                  element.ratingThreeStar.push(participant);
                  return "ratingThreeStar"
                  case 2:
                    //element.ratingTwoStar +=(`${participant} \n `);
                    element.ratingTwoStar.push(participant);
                    return "ratingTwoStar"
                    case 1:
                      //element.ratingOneStar +=(`${participant} \n `);
                      element.ratingOneStar.push(participant);
                      return "ratingOneStar"
                      case 0:
                        //element.ratingZeroStar +=(`${participant} \n `);
                        element.ratingZeroStar.push(participant);
                        return "ratingZeroStar"
                        case "XXX":
                          //element.ownImage +=(`${participant} \n `);
                          element.ownImage.push(participant);
                          return "ownImage"
                        
              
  
            default: console.log("Error bei der Zuordnung der Bewertung: ",ratingOld )
              break;
          }
          
       

        }

        else {

          if(similarity(element.name,imageName) > 0.49) {
          //console.error("Error in Bildauflösung, Falscher Bildername??")
          //console.error(element.name, imageName);
          }
        }
        
      });

    }
    }
    });


    setItems(bilder);
    //console.log("Endresultat");
    //console.log(bilder);
    var tableElement = document.getElementById("tableArea");
        var calculateDistributionCompleteBtn = document.getElementById("calculateDistributionCompleteBtn");
    tableElement.classList.remove("invisible");
    calculateDistributionCompleteBtn.classList.remove("invisible");


}
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: false,
  fileEncoding: "UTF-8"
  };

  function exportToExcel(params) {
    bilder = items;
    console.warn(bilder);
  }



class Bild{
  constructor(autor ="", name ="", ratingFiveStar =[], ratingFourStar =[], ratingThreeStar =[], ratingTwoStar =[], ratingOneStar =[], ratingZeroStar =[], ownImage =[], givenTo ="", special = false) {
    this.autor = autor;
    this.name = name;
    this.ratingFiveStar = ratingFiveStar;
    this.ratingFourStar = ratingFourStar;
    this.ratingThreeStar = ratingThreeStar;
    this.ratingTwoStar = ratingTwoStar;
    this.ratingOneStar = ratingOneStar;
    this.ratingZeroStar = ratingZeroStar;
    this.ownImage = ownImage;
    this.givenTo = givenTo;
    this.special = special;
  }
}

class GiftImageRatingAuthor {
  constructor(gifted ="", imageName ="", rating = 0, author ="") {
    this.gifted = gifted;
    this.imageName = imageName;
    this.rating = rating;
    this.author = author;
}
}


  class Autor{
    constructor(name ="", numberOfImages = 0, getImageCount = 0, images=[], score = 0, giftImage = [], quersumme = 0) {
      this.name = name;
      this.numberOfImages = numberOfImages;
      this.getImageCount = getImageCount;
      this.images = images;
      this.score = score;
      this.giftImage =  giftImage
      this.quersumme = quersumme;
  }




  


    
 
}



      

	

       return( 
        
            <React.Fragment>
            <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
              <h4>1️⃣ Uploade die Teilnehmerliste von www.kakao-karten.de:    </h4> 
              <input className="form-control" type="file"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
 </div> </div>
 </div>
 
 <div className="container">
              <div className="row justify-content-md-center">
              <div className="col-md-auto invisible" id="uploadRatings">
              <br></br>
<h4>2️⃣ Uploade die Bewertungsdatei aus deiner Umfrage:  </h4>     
    <CSVReader
      cssClass="form-control"
      label=""
      onFileLoaded={handleForce}
      parserOptions={papaparseOptions}
    />
   
 </div>
</div>
</div>       
           
<br></br>




            

 
 <button type="button" id="calculateDistributionCompleteBtn" onClick={calculateDistributionComplete} className="btn btn-primary invisible"> 🥳 Let the magic happen🎉</button>              
 <br></br>

 <ExcelFile element={ <button type="button" id="exportBtn" onClick={exportToExcel} className="btn btn-success invisible"> 🥳 Export to Excel🎉</button>     }>

            
                <ExcelSheet data={givenImages} name="Exchanged Images">
                    <ExcelColumn label="Beschenkter" value="gifted"/>
                    <ExcelColumn label="Bildname" value="imageName"/>
                    <ExcelColumn label="Künstler" value="author"/>
                    <ExcelColumn label="Persönliche Bewertung" value="rating"/>
                </ExcelSheet>
                <ExcelSheet data={autoren} name="Artists">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Submitted Images" value="numberOfImages"/>
                    <ExcelColumn label="Received Images" value="getImageCount"/>
                    <ExcelColumn label="Checksum" value="quersumme"/>
                    <ExcelColumn label="bilder" value="images"/>
                </ExcelSheet>

            </ExcelFile>

  
 <table id="tableArea" className="invisible">
   
  <thead>
    <tr>
            <th className="hide-scrollbar" scope="col">Autor</th>
            <th className="hide-scrollbar" scope="col">Bildname</th>
            <th className="hide-scrollbar" scope="col">5 ⭐⭐⭐⭐⭐</th>
            <th className="hide-scrollbar" scope="col">4 ⭐⭐⭐⭐</th>
            <th className="hide-scrollbar"  scope="col">3 ⭐⭐⭐</th>
            <th className="hide-scrollbar" scope="col">2 ⭐⭐</th>
            <th className="hide-scrollbar" scope="col">1 ⭐</th>
            <th className="hide-scrollbar" scope="col">0 ❌</th>
    </tr>
  </thead>
  <tbody>

  {items.map((x) => (
            <tr key={x.name}>
              <td scope="row" className="text-start hide-crollbar  ">{x.autor}</td>
              <td className="text-start hide-crollbar  ">{x.name}</td>
              <td className="text-start text-break hide-crollbar">{x.ratingFiveStar} </td>
              
              <td className="text-start hide-scrollbar ">{x.ratingFourStar}</td>
              <td className="text-start hide-scrollbar ">{x.ratingThreeStar}</td>
              <td className="text-start hide-scrollbar ">{x.ratingTwoStar}</td>
              <td className="text-start hide-scrollbar">{x.ratingOneStar}</td>
              <td className="text-start hide-scrollbar">{x.ratingZeroStar}</td>

            </tr>
          ))}
    
  </tbody>
</table>
            </React.Fragment>
       )};
       


   


export default Uploadtwo;