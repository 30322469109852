
import Uploadtwo from './Uploadtwo';
import './App.css';

function App() {
  return (
    <div className="App white">
      <header className="App-header">
      <h1 className="display-1">Hallo Fenja!</h1>
    
       <Uploadtwo/>
      </header>

    </div>
  );
}

export default App;
